$alert-border-opacity: 1;
$alert-font-size: 13px;
$breadcrumbs-item-font-size: 20px;
$btn-font-sizes: (
	"default": 1rem,
	"large": 1.3rem,
);
$btn-letter-spacing: inherit;
$btn-sizes: (
	"large": 50,
);
$btn-text-transform: none;
$btn-toggle-btn-opacity: 1;
$calendar-line-width: 0;
$chip-sizes: (
	"large": 40,
);
$data-table-regular-header-font-size: 14px;
$data-table-regular-row-font-size: 13px;
$expansion-panel-active-header-min-height: 0;
$expansion-panel-content-padding: 16px;
$expansion-panel-header-font-size: 16px;
$expansion-panel-header-min-height: 0;
$expansion-panel-header-padding: 12px 16px;
$font-size-root: 14px;
$headings: (
	"body-1": (
		"size": 18px,
		"letter-spacing": 0.5px,
		"line-height": 24px,
	),
	"body-2": (
		"size": 13px,
		"letter-spacing": 0.25px,
		"line-height": 20px,
	),
	"caption": (
		"size": 12px,
		"letter-spacing": 0.4px,
	),
	"h4": (
		"line-height": 48px,
		"size": 34px,
		"weight": 500,
	),
	"h5": (
		"line-height": 40px,
		"size": 24px,
		"weight": 500,
	),
	"h6": (
		"line-height": 32px,
		"size": 20px,
	),
	"overline": (
		"letter-spacing": 1.5px,
		"line-height": 16px,
		"size": 10px,
	),
	"subtitle-1": (
		"line-height": 24px,
		"size": 16px,
	),
	"subtitle-2": (
		"letter-spacing": 0.15px,
		"line-height": 20px,
		"size": 14px,
		"weight": 500,
	),
);
$input-font-size: 1.14rem;
$input-top-spacing: 8px;
$label-font-size: 14px;
$list-dense-min-height: 0;
$list-item-dense-title-font-size: 13px;
$list-item-dense-title-font-weight: normal;
//$list-item-title-font-size: 13px;
$list-item-subtitle-font-size: 10px;
$material-dark: (
	"tabs": rgba(255, 255, 255, 0.5),
);
$material-light: (
	"app-bar": #ffffff,
	"chips": #eaeaea,
	"dividers": rgba(0, 0, 0, 0.12),
	"input-bottom-line": rgba(0, 0, 0, 0.54),
	"table": (
		"hover": rgba(0, 0, 0, 0.08),
	),
	"text": (
		"disabled": rgba(0, 0, 0, 0.26) /*87)  for disabled breadcrumbs */,
		"secondary": rgba(0, 0, 0, 0.54),
	),
	"text-fields": (
		"filled": #e8e8e8,
		"filled-hover": rgba(0, 0, 0, 0.08),
	),
);
//$snackbar-background-color: white;
$stepper-elevation: 0;
$stepper-header-elevation: 0;
$tab-font-size: 14px;
$tabs-item-letter-spacing: 0.15px;
$snackbar-color: #FFFFFF;
